/* You can add global styles to this file, and also import other style files */
@import './sass/overwrite';

html{
  width: 100%;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  box-sizing: border-box;
  // overflow: auto;     Remove for Angular Dialog to work consistently
  line-height: normal;
  text-rendering: optimizeLegibility;
}


$silver: rgb(250, 250, 250);
$light: #FFFFFF;
$asana-blue: #2e3c54;
$asana-grey: #9ca6af;
$asana-highlight: #14aaf5;
$asana-background: #f8f9fa;
$asana-border: #cdcfd2;

$task-background: rgb(248, 248, 248);
$task-hover-border: rgb(221, 221, 221);;
$task-selected:  rgb(226, 244, 255);
$thin: 200;
$normal: 400;
$bold: 600;
$heavy: 800;


.mat-checkbox.mat-accent .mat-checkbox-inner-container {
  margin: 0;
}

.mat-checkbox .mat-checkbox-layout{
  margin: 0;
}

.btn:focus {
  outline: none !important;
}

.btn:focus {
  box-shadow: none;
}

.btn-custom-button, .btn-custom-uncheck {
  background: #fff;
  border-color: #cbd4db;
  color: #273240;
  fill: #6f7782;
  border-radius: 6px;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  padding: 0 8px;

}

.btn-custom-button:hover{
  background: #f6f6f6;
    border-color: #cbd4db;
    color: #151b26;
    fill: #273240;
}

.btn-custom-uncheck:hover {
  background: #e2fffa;
  border-color: #00bf9c;
  color: #00bf9c;
  fill: #00bf9c;
}

.btn-custom-check{
  background: #25e8c8;
    border-color: #25e8c8;
    color: #fff;
    fill: #fff;
    border-radius: 6px;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    padding: 0 8px;
}

.btn-custom-check:hover{
  background: #00bf9c;
  border-color: #00bf9c;
  color: #fff;
  fill: #fff;
}

button:focus, 
button:active:focus, 
button.active:focus {
    outline: none !important;
    outline-style: none !important;
}

